import QRCode from "qrcode.react";
import styled from "styled-components";

export const QRCodeContainer = styled.div`
                              display: flex;
                              flex-direction: column;
                              align-items: center;
                              margin: 1rem;
                            `;

export const QRCodeDisplay = ({ base64Value} : {base64Value: string}) => {
  return (
      <QRCodeContainer data-testid="qr-code-container">
        <QRCode value={base64Value} />
      </QRCodeContainer>
    );
};
