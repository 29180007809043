import { ReactNode } from "react";
import styled from "styled-components";

const Root = styled.div`
                    display: flex;
                    flex-direction: column;
                    min-height: 100vh;
                    height: 50px;
                    padding: 0;
                    margin: 0;
                    `;


export interface  ContainerProps {
  children: ReactNode[] | ReactNode;
}

export const Container: React.FC<ContainerProps> = ({children}) => {

  return (
        <Root data-testid="container">
          {children}
        </Root>
        );

};