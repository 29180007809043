import styled from "styled-components";

const Announcement = styled.div`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--headerColor);
                    height: 50px;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    `;


export interface  AnnouncementBarProps {
  message: string;
}

export const AnnouncementBar: React.FC<AnnouncementBarProps> = ({message}) => {

  return (
        <Announcement data-testid="announcement-bar">
            {message}
        </Announcement>
        );

};