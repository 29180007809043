import QRCodeForm from "./QR/QRCodeForm";
import { AnnouncementBar } from "./Layouts/AnnouncementBar";
import { Container } from "./Layouts/Container";
import { CopyRightsFooter } from "./Layouts/CopyRightsFooter";
import { ContentSection } from "./Layouts/ContentSection";

function App() {
  return (
    <Container>
      <AnnouncementBar message="Zatca E-Invoicing QR Code" />
      <ContentSection>
        <QRCodeForm />
      </ContentSection>
      <CopyRightsFooter message="© 2023 powered by eTree" />
    </Container>
  );
}

export default App;
