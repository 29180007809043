import React, { ReactNode } from "react";
import styled from "styled-components";

const Content = styled.div`
                      flex: 1;
                      padding: 20px;
                    `;


export interface  ContentSectionProps {
  children: ReactNode[] | ReactNode;
}

export const ContentSection: React.FC<ContentSectionProps> = ({children}) => {

  return (
        <Content data-testid="content">
          {children}
        </Content>
        );

};