import React, { ChangeEventHandler } from "react";
import {Label, Input} from "./FormComponents";

type FormFieldProps = {
  type: "text" | "number" | "datetime-local",
  label: string;
  id: string;
  name: string;
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const FormField: React.FC<FormFieldProps> = ({ type, label, id, name, value, onChange }) => {
  return (
      <>
        <Label htmlFor={id}>{label}</Label>
        <Input type={type} id={id} name={name} value={value} onChange={onChange} />
      </>
  );
};

