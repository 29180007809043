import { useState } from "react";
import { QRCodeDisplay } from "./QRCodeDisplay";
import { currentDateTime } from "../Utilities";
import { Button, FormContainer } from "../Form/FormComponents";
import { FormField } from "../Form/FormField";
import { QRCodeSpecifications } from "@Saluq/zatca-types";
import { formatURL } from "../BackendConfig";

const QRCodeForm = () => {
  const [inputData, setInputData] = useState({
    sellerName: "Bobs Records",
    VATRegistrationNumber: "310122393500003",
    invoiceDataTime: currentDateTime(),
    invoiceTotal: 2100100.99,
    VATTotal: 315015.15
  });

  const [qrCodeValue, setQRCodeValue] = useState<string>();
  const [error, setError] = useState<string>();

  const handleChange = (e: { target: { name: string; value: string; }; }) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setQRCodeValue(undefined);
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const qrCodeSpecifications: QRCodeSpecifications = { sellerName: inputData.sellerName, VATRegistrationNumber: inputData.VATRegistrationNumber,
      invoiceDataTime: new Date(inputData.invoiceDataTime),
      invoiceTotal: inputData.invoiceTotal, VATTotal: inputData.VATTotal };

      try {
        const response = await fetch(formatURL("qrcodes"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(qrCodeSpecifications)
        });

        const qrCode = await response.text();
        console.log(qrCode);
        setError(undefined);
        setQRCodeValue(qrCode);

      } catch (error) {
        if (error instanceof Error) setError(error.message);
        setQRCodeValue(undefined);
      }
  };

  return (
    <>
      <FormContainer>

      <FormField type="text" label="Seller Name:" id="sellerName" name="sellerName" value={inputData.sellerName} onChange={handleChange}/>

      <FormField type="text" label="VAT Registration Number:" id="VATRegistrationNumber" name="VATRegistrationNumber" value={inputData.VATRegistrationNumber} onChange={handleChange}/>

      <FormField type="datetime-local" label="Invoice Date Time:" id="invoiceDataTime" name="invoiceDataTime" value={inputData.invoiceDataTime} onChange={handleChange}/>

      <FormField type="number" label="Invoice Total:" id="invoiceTotal" name="invoiceTotal" value={inputData.invoiceTotal} onChange={handleChange}/>

      <FormField type="number" label="VAT Total:" id="VATTotal" name="VATTotal" value={inputData.VATTotal} onChange={handleChange}/>

      <Button type="submit" onClick={handleSubmit}>
        Generate QR Code
      </Button>

      </FormContainer>
      {error && (<div>{error}</div>)}
      {qrCodeValue && (<QRCodeDisplay base64Value={qrCodeValue} />)}
    </>
  );
};

export default QRCodeForm;
