import styled from "styled-components";

const CopyRight = styled.p`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    background-color: var(--footerColor);
                    height: 50px;
                    padding: 0;
                    margin: 0;
                    `;


export interface  CopyRightsFooterProps {
  message: string;
}

export const CopyRightsFooter: React.FC<CopyRightsFooterProps> = ({message}) => {

  return (
        <CopyRight data-testid="copyrights-footer">
            {message}
        </CopyRight>
        );

};